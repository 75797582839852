.typography-title-h1 {
  font-size: 1.688rem;
  line-height: 2.375rem;
  font-weight: 500;
}

.typography-title {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
}

.typography-body {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.typography-body-small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.typography-body-bold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.typography-sidebar-group {
  font-size: 0.75rem;
  line-height: 1.063rem;
  font-weight: 400;
}

.typography-sidebar-item {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.typography-pagination {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.typography-table-header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.typography-dropdown-menu {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 500;
}

.typography-dropdown-menu-small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.typography-multi-select {
  font-size: 0.75rem;
  line-height: 1.063rem;
  font-weight: 500;
}

.typography-page-title {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 400;
}

.typography-auth-title {
  font-size: 1.5rem;
  line-height: 1.813rem;
  font-weight: 500;
}